body {
  height: calc(100% - 4em);
}

.ui.inverted.menu {
  background: #ffcc00;
  border-bottom: 1px solid #58318e;
}

.ui.inverted.menu a.item:hover {
  color: black;
}

.ui.inverted.menu .item {
  color: #58318e;
}

.ui.inverted.menu .item:before {
  background: #58318e;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#slide_restricter {
  margin: 0 auto;
}

@media (min-height: 992px) and (max-height: 1200px) {
  #slide_restricter {
    max-width: 900px;
  }
}

@media (min-height: 768px) and (max-height: 992px) {
  #slide_restricter {
    max-width: 800px;
  }
}

@media (min-height: 600px) and (max-height: 768px) {
  #slide_restricter {
    max-width: 600px;
  }
}

@media (min-height: 0px) and (max-height: 600px) {
  #slide_restricter {
    max-width: 500px;
  }
}

.fullscreen-enabled {
  background: #fff;
}
